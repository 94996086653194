.banner.onload-content-fade-up .content {
  transition: none;
}

body.is-preload .banner.onload-content-fade-up .content {
  opacity: 1;
  transform: none;
}

.banner.onload-content-fade-down .content {
  transition: none;
}

body.is-preload .banner.onload-content-fade-down .content {
  opacity: 1;
  transform: none;
}

.banner.onload-content-fade-left .content {
  transition: none;
}

body.is-preload .banner.onload-content-fade-left .content {
  opacity: 1;
  transform: none;
}

.banner.onload-content-fade-right .content {
  transition: none;
}

body.is-preload .banner.onload-content-fade-right .content {
  opacity: 1;
  transform: none;
}

.banner.onload-content-fade-in .content {
  transition: none;
}

body.is-preload .banner.onload-content-fade-in .content {
  opacity: 1;
  transform: none;
}

.banner.onload-image-fade-up .image, .banner.onload-image-fade-up .image img {
  transition: none;
}

body.is-preload .banner.onload-image-fade-up .image {
  opacity: 1;
  transform: none;
}

body.is-preload .banner.onload-image-fade-up .image img {
  opacity: 1;
}

.banner.onload-image-fade-down .image, .banner.onload-image-fade-down .image img {
  transition: none;
}

body.is-preload .banner.onload-image-fade-down .image {
  opacity: 1;
  transform: none;
}

body.is-preload .banner.onload-image-fade-down .image img {
  opacity: 1;
}

.banner.onload-image-fade-left .image, .banner.onload-image-fade-left .image img {
  transition: none;
}

body.is-preload .banner.onload-image-fade-left .image {
  opacity: 1;
  transform: none;
}

body.is-preload .banner.onload-image-fade-left .image img {
  opacity: 1;
}

.banner.onload-image-fade-right .image, .banner.onload-image-fade-right .image img {
  transition: none;
}

body.is-preload .banner.onload-image-fade-right .image {
  opacity: 1;
  transform: none;
}

body.is-preload .banner.onload-image-fade-right .image img {
  opacity: 1;
}

.banner.onload-image-fade-in .image img {
  transition: none;
}

body.is-preload .banner.onload-image-fade-in .image img {
  opacity: 1;
}

.banner.onscroll-content-fade-up .content {
  transition: none;
}

.banner.onscroll-content-fade-up.is-inactive .content {
  opacity: 1;
  transform: none;
}

.banner.onscroll-content-fade-down .content {
  transition: none;
}

.banner.onscroll-content-fade-down.is-inactive .content {
  opacity: 1;
  transform: none;
}

.banner.onscroll-content-fade-left .content {
  transition: none;
}

.banner.onscroll-content-fade-left.is-inactive .content {
  opacity: 1;
  transform: none;
}

.banner.onscroll-content-fade-right .content {
  transition: none;
}

.banner.onscroll-content-fade-right.is-inactive .content {
  opacity: 1;
  transform: none;
}

.banner.onscroll-content-fade-in .content {
  transition: none;
}

.banner.onscroll-content-fade-in.is-inactive .content {
  opacity: 1;
  transform: none;
}

.banner.onscroll-image-fade-up .image, .banner.onscroll-image-fade-up .image img {
  transition: none;
}

.banner.onscroll-image-fade-up.is-inactive .image {
  opacity: 1;
  transform: none;
}

.banner.onscroll-image-fade-up.is-inactive .image img {
  opacity: 1;
}

.banner.onscroll-image-fade-down .image, .banner.onscroll-image-fade-down .image img {
  transition: none;
}

.banner.onscroll-image-fade-down.is-inactive .image {
  opacity: 1;
  transform: none;
}

.banner.onscroll-image-fade-down.is-inactive .image img {
  opacity: 1;
}

.banner.onscroll-image-fade-left .image, .banner.onscroll-image-fade-left .image img {
  transition: none;
}

.banner.onscroll-image-fade-left.is-inactive .image {
  opacity: 1;
  transform: none;
}

.banner.onscroll-image-fade-left.is-inactive .image img {
  opacity: 1;
}

.banner.onscroll-image-fade-right .image, .banner.onscroll-image-fade-right .image img {
  transition: none;
}

.banner.onscroll-image-fade-right.is-inactive .image {
  opacity: 1;
  transform: none;
}

.banner.onscroll-image-fade-right.is-inactive .image img {
  opacity: 1;
}

.banner.onscroll-image-fade-in .image img {
  transition: none;
}

.banner.onscroll-image-fade-in.is-inactive .image img {
  opacity: 1;
}
/*# sourceMappingURL=index.da97d155.css.map */
